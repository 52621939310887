


























import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class UIDialogChangePushFixed extends Vue {
  @Prop() value!: boolean;
  @Prop() fixedChangingName!: string;
  @Prop() fixedStatus!: string;
  @Prop() changeFixedCallback!: () => void;

  @Emit()
  public input(value: boolean) {}

  private get localValue(): boolean {
    return this.value;
  }

  private set localValue(value: boolean) {
    this.input(value);
  }
}
