import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"320"},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},[_c(VCard,[_c(VCardTitle,[_vm._v("確認")]),(_vm.fixedStatus == '設定なし')?[_c(VCardText,[_vm._v("「"+_vm._s(_vm.fixedChangingName)+"」の固定表示を設定しますか？")])]:[_c(VCardText,[_vm._v("「"+_vm._s(_vm.fixedChangingName)+"」の固定表示を解除しますか？")])],_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.localValue = false}}},[_vm._v("キャンセル")]),(_vm.fixedStatus == '設定なし')?_c('div',[_c(VBtn,{attrs:{"color":"red","text":""},on:{"click":_vm.changeFixedCallback}},[_vm._v("設定する")])],1):_c('div',[_c(VBtn,{attrs:{"color":"red","text":""},on:{"click":_vm.changeFixedCallback}},[_vm._v("解除する")])],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }